import { makeStyles, createStyles } from "@material-ui/core/styles";

const SignInFormStyles = makeStyles((theme: any) =>
  createStyles({
    signInFormHolder: {
      textAlign: "center",
    },
    signInHeader: {
      fontSize: 20,
      color: "#707070",
      display: "inline-block",
      position: "relative",
      top: 20,
    },
    signInHeaderNewPassword: {
      top: 40,
    },
    usernameInput: {
      width: "100%",
      position: "relative",
      top: 100,
      "&::placeholder": {
        color: "rgb(0, 0, 0, 1)",
        opacity: 1,
      },
    },
    passwordInput: {
      width: "100%",
      position: "relative",
      top: 140,
    },
    newPasswordInput: {
      width: "100%",
      position: "relative",
      top: 115,
    },
    newPasswordConfirmInput: {
      width: "100%",
      position: "relative",
      top: 120,
    },
    firstNameInput: {
      width: "100%",
      position: "relative",
      top: 80,
    },
    lastNameInput: {
      width: "100%",
      position: "relative",
      top: 90,
    },
    loginButton: {
      width: "100%",
      backgroundColor: "#00B6FF",
      top: 170,
      borderRadius: 4,
      padding: 0,
      height: 54,
    },
    loginButtonlabel: {
      fontWeight: 400,
    },
    saveButton: {
      float: "left",
      top: 150,
      backgroundColor: "green",
    },
    visibleIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    error: {
      color: "red",
      fontSize: 14,
      display: "block",
      position: "relative",
      top: 25,
    },
    errorNewPassword: {
      color: "red",
      fontSize: 14,
      display: "block",
      position: "relative",
      top: 60,
    },
    loadingSpinner: {
      position: "absolute",
      color: "#FF0092",
      top: 200,
      left: 0,
      right: 0,
      bottom: 120,
      margin: "auto",
    },
    loadingSpinnerPassword: {
      position: "absolute",
      bottom: 35,
      right: 0,
      color: "grey",
    },
    forgotPasswordLink: {
      textDecoration: "none",
      color: "#868686",
      position: "relative",
      float: "left",
      fontSize: 14,
      display: "inline-block",
      top: 270,
      cursor: "pointer",
      "&hover": {
        cursor: "pointer !important",
      },
    },
    passwordHasBeenChangedMessage: {
      fontSize: 14,
      position: "relative",
      top: 75,
      color: "#707070",
      marginBottom: -5,
    },
    loadingText: {
      color: "#3B3B3B",
      position: "relative",
      textAlign: "center",
      top: 260,
    },
  })
);

export { SignInFormStyles };
