import { Button, InputAdornment, Link } from "@material-ui/core";
import { CircularProgress, TextField, Typography } from "@material-ui/core";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import React, { ReactElement, useEffect, useState } from "react";
import VisibleIcon from "@material-ui/icons/Visibility";
import axios, { AxiosResponse } from "axios";

import styles from "./SignInForm.module.css";
import { SignInFormStyles } from "./SignInFormStyles";
import { Utilities } from "../../utilities/Utilities";

interface Props {}

export default function SignInForm({}: Props): ReactElement {
  const [loadingSpinner, toggleLoadingSpinner] = useState(false);
  const [loginErrorMessageStore, setLoginErrorMessageStore] = useState([]);

  const [usernameError, toggleUsernameError] = useState(false);
  const [username, setUsername] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, togglePasswordError] = useState(false);

  const [showPassword, toggleShowPassword] = useState(false);

  useEffect(() => {
    let usernameInput = document.getElementById("usernameInput");
    let passwordInput = document.getElementById("passwordInput");
    let enterButton = document.getElementById("enterButton");

    usernameInput.addEventListener("keyup", function (event) {
      if (event.key === "Enter") {
        enterButton.click();
      }
    });

    passwordInput.addEventListener("keyup", function (event) {
      if (event.key === "Enter") {
        enterButton.click();
      }
    });

    return function cleanup() {
      let usernameInput = document.getElementById("usernameInput");
      let passwordInput = document.getElementById("passwordInput");
      let enterButton = document.getElementById("enterButton");

      if (usernameInput) {
        usernameInput.removeEventListener("keyup", function (event) {
          if (event.key === "Enter") {
            enterButton.click();
          }
        });
      }

      if (passwordInput) {
        passwordInput.removeEventListener("keyup", function (event) {
          if (event.key === "Enter") {
            enterButton.click();
          }
        });
      }
    };
  }, []);

  const handleUsernameChange = (e) => {
    toggleUsernameError(false);
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    togglePasswordError(false);
    setPassword(e.target.value);
  };

  const handleLoginButtonClick = () => {
    //Remove passwordHasBeenChanged message
    setLoginErrorMessageStore([]);
    toggleLoadingSpinner(true);

    if (username === "" && password !== "") {
      toggleUsernameError(true);
      toggleLoadingSpinner(false);
      return;
    } else if (username === "" && password === "") {
      toggleUsernameError(true);
      togglePasswordError(true);
      toggleLoadingSpinner(false);
      return;
    } else if (username !== "" && password === "") {
      togglePasswordError(true);
      toggleLoadingSpinner(false);
      return;
    }

    var authenticationData = {
      Username: username,
      Password: password,
    };

    var authenticationDetails = new AuthenticationDetails(authenticationData);

    var poolData = {
      UserPoolId: "us-east-1_N15Q0NLkm",
      ClientId: "2332rbhi35f5016dglri2mojo",
    };

    var userPool = new CognitoUserPool(poolData);

    var userData = {
      Username: username,
      Pool: userPool,
    };

    var cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        handleLoginSuccess(result);
      },
      onFailure: function (err) {
        console.log("FAILURE", err);
        setLoginErrorMessageStore((loginErrorMessageStore) => [
          ...loginErrorMessageStore,
          err.message,
        ]);

        toggleLoadingSpinner(false);

        //Re-add event listeners
        let usernameInput = document.getElementById("usernameInput");
        let passwordInput = document.getElementById("passwordInput");
        let enterButton = document.getElementById("enterButton");

        usernameInput.addEventListener("keyup", function (event) {
          if (event.key === "Enter") {
            enterButton.click();
          }
        });

        passwordInput.addEventListener("keyup", function (event) {
          if (event.key === "Enter") {
            enterButton.click();
          }
        });
      },
    });
  };

  const handleLoginSuccess = async (result) => {
    console.log("Login success", result);

    let projectsAndSpacesResponse = await handleGetProjects(
      result.accessToken.payload.sub
    );

    let search = window.location.search;
    let params = new URLSearchParams(search);

    //Redirect to GoogleAuth redirect URI
    if (params.get("redirect_uri")) {
      let redirectUri = params.get("redirect_uri");

      //Append state token to redirect_uri
      let stateToken = params.get("state");
      if (stateToken) {
        redirectUri =
          redirectUri.slice(0, -12) + "usercallback?state=" + stateToken;

        //Generate code to send back as a query parameter
        let code = Utilities.generateOAuthCode(
          result.accessToken.payload.sub,
          result.getIdToken().getJwtToken()
        );

        redirectUri = redirectUri + `&code=${code}`;

        //Update producer record to include oauth code
        let updateRecord = await updateProducerRecord(
          result.accessToken.payload.sub,
          code,
          result.getIdToken().getJwtToken()
        );

        if (updateRecord) {
          window.location.replace(redirectUri);
        } else {
          //TODO:
          //Error handling
        }
      } else {
        //TODO:
        //error handling
      }
    } else {
      //TODO:
      //error handling
    }
  };

  //Toggle password visibility
  const handleVisibilityClick = () => {
    toggleShowPassword(!showPassword);
  };

  //Get projects/spaces for producer
  const handleGetProjects = async (producerID) => {
    return new Promise(async function (resolve, reject) {
      try {
        await axios.get("/projects-and-spaces", {
          params: {
            producerID: producerID,
          },
        });

        //Set projects in context
        resolve(true);
      } catch (err) {
        resolve(false);
      }
    });
  };

  //Update producer record
  const updateProducerRecord = async (producerId, code, idToken) => {
    try {
      let response = await axios.put(
        process.env.REACT_APP_AUTH_URL + "/producer/assign",
        {
          producerId: producerId,
          code: code,
          clientId: process.env.REACT_APP_CLIENT_ID,
        },
        {
          headers: {
            "Authorization": idToken
          }
        }
      );

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const classes = SignInFormStyles();

  return (
    <div className={styles.signInFormHolder}>
      {loadingSpinner && (
        <React.Fragment>
          <CircularProgress className={classes.loadingSpinner} />
          <Typography variant="body1" className={classes.loadingText}>
            Loading
          </Typography>
        </React.Fragment>
      )}

      {!loadingSpinner && (
        <React.Fragment>
          <div className={styles.errorHolder}>
            {loginErrorMessageStore.map((error) => {
              return (
                <Typography
                  key={error}
                  classes={{ root: classes.error }}
                  variant="h1"
                >
                  {error}
                </Typography>
              );
            })}
          </div>

          <TextField
            variant="outlined"
            placeholder="Username"
            fullWidth
            id="usernameInput"
            classes={{ root: classes.usernameInput }}
            onChange={handleUsernameChange}
            value={username}
            error={usernameError}
          ></TextField>

          <TextField
            variant="outlined"
            placeholder="Password"
            fullWidth
            id="passwordInput"
            type={showPassword ? "text" : "password"}
            classes={{ root: classes.passwordInput }}
            onChange={handlePasswordChange}
            value={password}
            error={passwordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VisibleIcon
                    className={classes.visibleIcon}
                    onClick={handleVisibilityClick}
                  />
                </InputAdornment>
              ),
            }}
          ></TextField>

          {loadingSpinner && (
            <CircularProgress className={classes.loadingSpinner} />
          )}

          {/*     <Link
            onClick={handleForgotPasswordClick}
            className={classes.forgotPasswordLink}
          >
            Forgot Password?
          </Link> */}

          <Button
            variant="contained"
            color="primary"
            id="enterButton"
            disabled={loadingSpinner}
            classes={{
              root: classes.loginButton,
              label: classes.loginButtonlabel,
            }}
            onClick={handleLoginButtonClick}
          >
            LOGIN
          </Button>
        </React.Fragment>
      )}
    </div>
  );
}
