import { Paper } from "@material-ui/core";
import React, { ReactElement } from "react";
import SignInForm from "../../components/SignInForm/SignInForm";

import styles from "./Login.module.css";
import { LoginStyles } from "./LoginStyles";

interface Props {}

export default function Login({}: Props): ReactElement {
  const classes = LoginStyles();

  return (
    <Paper elevation={0} className={classes.loginContentHolder}>
      <div className={styles.showboatLogoHolder}>
        <img
          alt="Showboat logo"
          src="assets/images/ShowboatLogo.svg"
          className={styles.showboatLogo}
        />
      </div>
      <SignInForm />
    </Paper>
  );
}
