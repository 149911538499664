import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/* if (
  process.env.REACT_APP_API_STAGE === "dev"
) { */
  axios.defaults.baseURL = `https://zeazen0t8i.execute-api.us-east-1.amazonaws.com/dev`;
/* } else {
  axios.defaults.baseURL = `https://zeazen0t8i.execute-api.us-east-1.amazonaws.com/prod`;
}
 */
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
