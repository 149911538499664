import logo from "./logo.svg";
import "./App.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import Login from "./Pages/Login/Login";

function App() {
  /* Material UI Theme */
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#868686",
      },
      secondary: {
        main: "#FF0092",
      },
    },
    breakpoints: {
      values: {
        lg: 1281,
        md: 960,
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "*": {
            "scrollbar-width": "thin",
          },
          "*::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider>
      <div className="app" id="app">
        <Login />
      </div>
    </ThemeProvider>
  );
}

export default App;
